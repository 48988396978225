import I18nJS from 'i18n-js';

import { translations } from '../constants/i18nTranslations';

// Prevent double rendering in React views
document.addEventListener('turbolinks:before-visit', () => {
  if (window.Turbolinks) {
    window.Turbolinks.clearCache();
    // Somewhat of a hack to fix back button issues
    window.history.replaceState({ turbolinks: {} }, '');
  }
});

I18nJS.translations = translations;

window.global = window;

export const AccountTranslations = window.AccountTranslations;
export const jQuery = window.jQuery;
export const I18n = I18nJS;
